export enum ProductHandles {
  // Annual Memberships
  "unlimited-vet-advice-annual-membership-insurance" = "unlimited-vet-advice-annual-membership-insurance",
  "unlimited-vet-advice-annual-membership-6-mo" = "unlimited-vet-advice-annual-membership-6-mo",
  "unlimited-vet-advice-2-year-membership" = "unlimited-vet-advice-2-year-membership",
  "unlimited-vet-advice-87-12mo" = "unlimited-vet-advice-87-12mo",

  // Monthly Memberships
  "unlimited-vet-advice" = "unlimited-vet-advice",
  "unlimited-vet-advice-30" = "unlimited-vet-advice-30",
  "unlimited-vet-advice-39" = "unlimited-vet-advice-39",
  "unlimited-vet-advice-45" = "unlimited-vet-advice-45",
  "unlimited-vet-advice-75" = "unlimited-vet-advice-75",
  "unlimited-vet-advice-insurance-40" = "unlimited-vet-advice-insurance-40",
  "unlimited-vet-advice-insurance-monthly-membership" = "unlimited-vet-advice-insurance-monthly-membership",

  // 3/6 Month Fixed Term
  "unlimited-vet-advice-3-month" = "unlimited-vet-advice-3-month",
  "unlimited-vet-advice-6-month-membership-1" = "unlimited-vet-advice-6-month-membership-1",
  "unlimited-vet-advice-29-3mo" = "unlimited-vet-advice-29-3mo",
  "unlimited-vet-advice-35-3mo" = "unlimited-vet-advice-35-3mo",

  // Insurance Products
  "emergency-insurance" = "emergency-insurance",
  "accident-insurance" = "accident-insurance",
  "unlimited-vet-advice-accident-insurance" = "unlimited-vet-advice-accident-insurance",
  "unlimited-vet-advice-accident-insurance-first-month" = "unlimited-vet-advice-accident-insurance-first-month",

  // Fees
  "initiation-fee" = "one-time-joining-fee",
  "add-pet-fee" = "add-pet-fee",

  // 1 time call
  "1-vet-call" = "1-vet-call",
}
